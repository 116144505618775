import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./platform-extensibility@gen.scss";

const GeneratedProductFeaturesPlatformExtensibility = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          プラットフォームの拡張性 - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="簡単に拡張可能なインシデント管理プラットフォーム"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="簡単に拡張可能なインシデント管理プラットフォーム"
        />

        <meta
          property="og:title"
          content="プラットフォームの拡張性 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="簡単に拡張可能なインシデント管理プラットフォーム"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/platform-extensibility/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/platform-extensibility/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="プラットフォームの拡張性"
        overview=""
      />

      <div className="peWrapper">
        <div className="center-2 first-section">
          <h2 className="center-title">
            簡単に拡張可能なインシデント管理プラットフォーム
          </h2>
          <p className="center-para">
            PagerDutyのインシデント管理プラットフォームを簡単に拡張できます。拡張された開発者ポータル、SDK、コード例などの新しい開発リソースを活用して、管理、監視、およびChatOpsツールを統合します。
          </p>
        </div>
        <div className="max-930px-img">
          <img
            className="object-contain"
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/platform_extensibility_1de049fede.png"
            alt="簡単に拡張可能なインシデント管理プラットフォーム"
          />
        </div>
        {/* details */}
        <div className="center-1">
          <h2 className="center-title">プラットフォーム拡張性の詳細</h2>
        </div>
        <div className="three-col-without-cta">
          <div className="card">
            <div className="title">
              <p>イベントAPI v2</p>
            </div>
            <p>
              拡張されたイベントAPIはインバウンドイベントを自動的に正規化し、すべてのイベントデータを簡単に細かく分けることが可能です。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                スピードと
                <br />
                パフォーマンスを提供
              </p>
            </div>
            <p>
              優れたAPIの一貫性とパフォーマンスを備えた
              <a
                href="https://developer.pagerduty.com/docs/ZG9jOjExMDI5NTgw-events-api-v2-overview"
                className="text-[#048a24]"
                target="_blank"
              >
                最新のAPI（v2）を使用
              </a>
              して、開発チームからより迅速な配信を可能にします。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>開発者向けポータル</p>
            </div>
            <p>
              任意のサービスやツールのリソースをPagerDutyプラットフォームに接続するときは、デベロッパーポータルを活用してください。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>アドオンAPI</p>
            </div>
            <p>
              独自のカスタムコードを記述してPagerDuty内のiframeに直接埋め込んで、ユーザ機能を拡張してください。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>インシデントエンリッチメント</p>
            </div>
            <p>
              リアルタイムのメトリックとコンテキスト情報をインシデント内に直接埋め込むことで、MTTAとMTTRを削減します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>
                カスタムイベント
                <br />
                トランスフォーマー
              </p>
            </div>
            <p>
              HTTPリクエストを送信できるツールから送信されたペイロードを、PagerDutyが理解できるペイロードに変換します。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>すぐに使用できる拡張性</p>
            </div>
            <p>
              PagerDutyは、150以上のネイティブ統合により、エコシステムの拡張性の業界リーダーです。統合の詳細については、Integrationsのページをご覧ください。
            </p>
          </div>
          <div className="card">
            <div className="title">
              <p>ワークフロー拡張</p>
            </div>
            <p>
              異なるチームやサービスに一貫した情報を提供するための双方向の情報フローのために、PagerDutyと他のサードパーティのソリューション間の運用ワークフローを構成します。
            </p>
          </div>
        </div>
        <div className="conclusion">
          PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
          <br />
          インシデントをより迅速に解決するのに役立ちます。
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesPlatformExtensibility;
